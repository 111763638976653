<script setup lang="ts">
import AppBar from "~/components/App/AppBar.vue"
import NavDrawer from "~/components/App/NavDrawer.vue"
import { defineAsyncComponent } from "vue"

const Init = defineAsyncComponent(() => import("@/components/Init/Init.vue"))
</script>

<template>
  <div>
    <AppBar />
    <NavDrawer />
    <v-main class="px-4 my-4">
      <Init />
      <div class="page-content-centred">
        <slot />
      </div>
    </v-main>
  </div>
</template>

<style scoped lang="scss">
.page-content-centred {
  margin: 0 auto;
  max-width: 768px;
}
</style>
